.qb-grid {
    display: grid;
    grid-template-columns: 7vw repeat(4,18vw);
    grid-template-rows: 3vh repeat(8,9.5vh);
    grid-gap: 1px;
}

.area-title {
    justify-self: center;
    align-self: center;
    font-size: large;
    font-weight: bold;
}