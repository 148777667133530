#rai-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#rai-input {
    width: 80%;
    height: 40vh;
}

#rai-submit {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

@media only screen and (max-width: 1000px) {
    #rai-content-div {
        font-size: 14px !important;
    }
    #rai-input {
        box-sizing: content-box;
        height: 35vh;
    }
}