#admin-board-links-div {
    display: flex;
    justify-content: space-evenly;
}

.admin-board-subtitles {
    margin-left: 6vw;
    margin-top: 3vh;
}
            
#admin-board-summary-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#admin-board-ubpp-table {
    align-self: stretch;
    max-width: 80vw;
}