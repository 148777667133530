#article-parent-div {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 1.5%;
    padding-bottom: 3%;
}

#article-tags {
    margin-top: 3%;
    margin-bottom: 3%;
}

#article-title {
    font-size: 50px;
}

#article-image {
    margin-top: 1%;
    margin-bottom: 1%;
}

@media only screen and (max-width: 1000px) {
    #article-image {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}