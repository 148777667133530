#join-private-leaderboard-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
}

#join-private-leaderboard-uuid-input-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 7vh;
}

#join-private-leaderboard-input-label {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 3vw;
}

#join-leaderboard-submit {
    margin-top: 7vh;
}

#join-private-leaderboard-uuid-input {
    width: 20vw;
}