#mobile-nav-bar {
    border-bottom: 0.01px solid white;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mobile-report-an-issue-div {
    display: flex;
    /* flex-grow: 1; */
    justify-content: flex-end;
    justify-content: right;
    margin-right: 1vw;
}

#mobile-report-an-issue-link {
    border: 1px solid grey;
    padding: 3px;
}

#mobile-menu-link {
    color: rgba(255,255,255,.75);
}