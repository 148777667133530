@media only screen and (max-width: 1000px) {
    .qb_displayer_box {
        height: 9vh;
        border-radius: 20px !important;
        margin-bottom: 1px;
    }
    .qb-displayer-selection {
        font-size: 8pt !important;
        /* justify-content: center;
        justify-items: center;
        justify-self: center;
        align-items: center;
        align-self: center;
        align-content: center; */
    }
    #qb-prediction-history-season-selector-div {
        display: flex;
        width: 50vw !important;
        justify-content: space-around;
    }
}

.qb_displayer_box {
    display: grid;
    grid-template-columns: 1fr 15% 50% 10%;
    grid-template-rows: 2% repeat(4,24%) 2%;
    border-radius: 25px;
}

.qb-displayer-selection {
    font-size: 9pt;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.pp {
    font-style: italic;
}

.colour-red {
    background-color: red;
}

.colour-green {
    background-color: green;
    /* background-color:#28f511; */
}

.colour-white {
    background-color: white;
}

.qb_displayer_logo {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 7;
    justify-self: center;
    align-self: center;
    max-width: 80%;
    max-height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.qb_selector_select {
    /* float: right;
    width: 14vw; */
    /* border:1px solid green; */
    grid-column: 2;
    justify-self: stretch;
    align-self: center;
    max-width: 100%;
    padding-right: 5px;
}

#prediction-period-changer {
    display: grid;
    /* grid-template-columns: 10% 20% 15% 20% 10% 25%; */
    /* grid-template-rows: 100%; */
    grid-template-columns: 30% 40% 40%;
    grid-template-rows: 40% 60%;
    /* border: 1px solid black; */
}

.prediction-period-changer-text {
    /* border: 1px solid blue; */
    justify-self: center;
    align-self: center;
}

.prediction-period-changer-selecter {
    justify-self: center;
    align-self: center;
}

.AFC {
    background-color: rgba(206,17,38,0.8);
}

.NFC {
    background-color: rgba(0,59,102,0.8);
}

.conference_logo {
    /* max-width: 100%; */
    max-width: 5vw;
    align-self: center;
     /* border: 1px solid purple; */
}

#prediction-period-changer-button-div {
    display: grid;
    grid-template-columns: 50% 50%;
}


#qb-displayer-selection {
    display: block;
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
  }

#qb-history-container {
    display: flex;
    align-items: center;
    /* width: 100vw; */
    /* border: 1px solid blue; */
    /* padding-left: 0;
    margin-left: 0; */  
    justify-content: space-evenly;
}

#qb-prediction-history-score-table {
    /* height: 50%; */
}

.usdt-sp {
    width: 5vw;
}

.usdt-val {
    width: 5vw;
}

#qb-prediction-history-right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid blueviolet; */
    justify-content: space-around;
    height: 80vh;
}

#qb-prediction-history-season-selector-div {
    display: flex;
    /* align-items: center; */
    width: 12vw;
    justify-content: space-around;
}

#qb-prediction-history-season-updater-button {
    margin-left: 5%;
}