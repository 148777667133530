.qb_selector_select {
    /* float: right;
    width: 14vw; */
    /* border:1px solid green; */
    grid-column: 2;
    justify-self: stretch;
    align-self: center;
    max-width: 100%;
    padding-right: 5px;
}

#message-and-button {
    grid-column: 5;
    grid-row: 10;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

#qbp-save-button {
    width: 9vw;
}

#bottom-message {
    width: 9vw;
    text-align: center;
}

#prediction-period-changer {
    display: grid;
    /* grid-template-columns: 10% 20% 15% 20% 10% 25%; */
    /* grid-template-rows: 100%; */
    grid-template-columns: 30% 40% 40%;
    grid-template-rows: 40% 60%;
    /* border: 1px solid black; */
}

.prediction-period-changer-text {
    /* border: 1px solid blue; */
    justify-self: center;
    align-self: center;
}

.prediction-period-changer-selecter {
    justify-self: center;
    align-self: center;
}



.AFC {
    background-color: rgba(206,17,38,0.8);
}

.NFC {
    background-color: rgba(0,59,102,0.8);
}

.conference_logo {
    /* max-width: 100%; */
    max-width: 5vw;
    align-self: center;
     /* border: 1px solid purple; */
}

#prediction-period-changer-button-div {
    display: grid;
    grid-template-columns: 50% 50%;
}

#reddit-logo {
    height: 6vh;
}

#social-media-request {
    grid-row: 10;
    grid-column-start: 2;
    grid-column-end: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px dashed black;
    margin-top: 1vh;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    #social-media-request {
        display: grid;
        grid-template-columns: 66vw 1fr;
        grid-template-rows: 8vh 10vh;
    }
    #social-media-text {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    #social-media-twitter {
        grid-row: 2;
        grid-column: 1;
    }
    #social-media-reddit {
        grid-row: 2;
        grid-column: 2;
    }
    #message-and-button {
        justify-content: space-between;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    #qbp-save-button {
        width: 30vw;
        margin-right: 3vw;
    }
    #bottom-message {
        width: 25vw;
        margin-left: 3vw;
    }
}