/* #answerEntryModal {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  transform: translate(-50%, -50%);

}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  } */

#answerEntryTablesDiv {
	display: flex;
    justify-content: space-evenly;
}
.answer-entry-table table,
.answer-entry-table th,
.answer-entry-table td {
  border: 1px solid black;
}

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tqbcModal {
  /* width: 250px; */
  /* height: 170px; */
  min-height: 50vh;
  height: max-content;
  width: 50vw;
  position: relative;
  /* padding-top: 10vh; */
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow: overlay;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */

}

.modalContent {
  /* padding: 10px; */
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  /* overflow: overlay; */
  /* border: 1px solid green; */
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 2%;
  width: 100%;
  display: flex;
  justify-content: right;
  /* border: 1px solid brown; */
}

.modalButton {
	margin-right: 5%;
}

.answer-cell {
  /* color: green; */
}

.answer-table-col-header {
  /* padding-left: 10px;
  padding-right: 10px; */
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.answerSelector {
	width: 80%;
}

.removeAnswerSelector {
	width: 10%;
}

.answerSelectorDiv {
	display: flex;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	padding-bottom: 2%;
}