.qb-selector-box {
    display: grid;
    grid-template-columns: 1fr 75%;
    border-radius: 25px;
}

.qb-selector-logo {
    grid-column: 1;
    justify-self: center;
    align-self: center;
    max-width: 80%;
    max-height: 100%;
    object-fit: cover;
    overflow: hidden;
}

@media only screen and (max-width: 1000px) {
    .qb-selector-box {
        height: 9vh;
        border-radius: 20px;
        font-size: 14px;
        margin-bottom: 1px;
    }
    
    .qb-selector-logo {

    }
}