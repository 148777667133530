@media only screen and (max-width: 1000px) {
    #create-new-private-leaderboard-grid {
        grid-template-columns: 60vw 19vw 2vw 19vw !important;
        grid-template-rows: 10vh 5vh 7vh repeat(4,9.5vh) 5vh !important;
    }
    #weightings-text {
        margin-left: 3vw;
    }
}

#create-new-private-leaderboard-grid {
    display: grid;
    /* grid-template-columns: repeat(4,10vw);
    grid-template-rows: repeat(2,10vh); */
    grid-template-columns: 20vw 10vw 2vw 10vw;
    grid-template-rows: 10vh 5vh 7vh repeat(4,10vh) 5vh;
    text-align: center;
    align-items: center;
    justify-items: center;
}

.npl-text {
    /* text-align: center; */
}

/* #new-private-leaderboard-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
} */

#new-private-leaderboard-div {
    /* margin-top: 3vh; */
}

#new-private-leaderboard-submit {
    margin-top: 3vh;
}


#new-private-leaderboard-title {
}

.empty-square {
    border: 1px solid red;
    height: 10vh;
    width: 42vw;
}

#weightings-text {
    grid-row: 3;
    grid-column: 1;
    justify-self: left;
}