.arrows {
    width: 3vw;
    height: 3vw;
}

table,th,td {
    text-align: center;
}

tr {
    height: 3vh;
}

.border-tlb {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding-left: 5vw;
    padding-right: 5vw;
}

.border-trb {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding-left: 2vw;
    padding-right: 2vw;
}

.border-rb {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding-left: 2vw;
    padding-right: 2vw;
}

.border-lb {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding-left: 2vw;
    padding-right: 2vw;
}

.ps-col {
    padding-left: 1vw;
    padding-right: 1vw;
}

.ps-val {
    border: 1px solid black;
}

.sp-val {
    padding-left: 3vw;
    padding-right: 3vw;
}

/* table { */
#how-it-works-table {
    border-collapse: separate !important;
}

.arrow-col {
    margin-left: 1vw;
    margin-right: 1vw;
}

.heading-tlb-border {
    border-top: 0.5px solid black;
    border-left: 0.5px solid black;
    border-bottom: 0.5px solid black;
}

.heading-tlr-border {
    border-top: 0.5px solid black;
    border-left: 0.5px solid black;
    border-right: 0.5px solid black;
}

.heading-tbr-border {
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
    border-right: 0.5px solid black;
}

.heading-tl-border {
    border-top: 0.5px solid black;
    border-left: 0.5px solid black;
}

@media only screen and (max-width: 1000px) {
    #how-it-works-table {
        font-size: 13px;
    }
    .empty-td {
        height: 8vh;
    }
}