#fpee-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
}

#fpee-email-div {
    display: flex;
    flex-direction: row;
}

#fpee-email-input {
    margin-left: 3vw;
    text-align: center;
    width: 20vw;
}

#fpee-button {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

#fppe-email-div-grid {
    display: grid;
    grid-template-columns: 15vw 25vw;
    grid-template-rows: repeat(2,15vh);
    align-items: center;
    text-align: center;
}

#fppe-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fppe-input {
    margin-left: 2vw;
    text-align: center;
}

#fppe-button {
    margin-top: 5vh;
    margin-bottom: 5vh;
}