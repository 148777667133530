#generic-leaderboard-table {
    /* border: 1px solid black; */
    border-collapse: collapse;
    font-size: 14px;
    /* border: 1px solid red; */
}

#rank-header {
    min-width: 10vw;
}

#user-header {
    min-width: 20vw;
}

.leaderboard-titles {
}

#title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#leaderboard-title-left {
    border: 2px solid black;
    /* display: inline; */
    width: fit-content;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 3vw;    
}

#leaderboard-title-right {

}

.sp-col {
    width: 10vw;
}

.br {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.bl {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}

.blr {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
}

.tlr {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
}

/* .leaderboard-header :hover {
    background-color: yellow;
} */

.leaderboard-header {
    cursor: pointer;
}

.user-row {
    border: 1.9px dashed black;
    padding-left: 1vw;
    padding-right: 1vw;
}

#page-selector-div {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
    /* border: 1px solid green; */

}

.page-button {
    margin-left: 1vw;
}

.selected-page-button {
    /* background-color: black;
    color: white; */
    border: 2px solid black;
    background-color: white;
}

.unselected-page-button {
    /* background-color: white;
    color: black; */
    border: 0px;
    background-color: white;
}

#generic-leaderboard-parent-div {
    display: flex;
    flex-direction: row;
}

#generic-leaderboard-left {
    display: flex;
    flex-direction: column;
    margin-top: 0.5vh;
    /* border: 1px solid black; */
    margin-left: 2vw;
}

#generic-leaderboard-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid blueviolet; */
    justify-content: space-around;
    height: 80vh;
    /* width: 10vw; */
    width: 16vw;
    margin-top: 3vh;
    /* margin-left: 4vw; */
    margin-left: 1vw;
}

#popup-weightings-table {
    margin-left: 2vw;
}

#right-weightings-table {
    /* margin-left: 2vw; */
}

#weightings-table-headers {
    font-style: italic;
    font-weight: normal !important;
}

.weightings-table-headers {
    font-weight: normal;
    width: 8vw;
}