.navbar-dropdown {
    margin-top: 8vh;
    position: absolute;
	/* border: 1px solid red; */
	width: 10.5vw;
    border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
    z-index: 1;
}

.navbar-dropdown-divider {
	border-top: 1px solid grey;
	width: 20%;
	margin-left: 40%;
}

#private-leaderboard-link {
	/* border: 1px solid red; */
	display: flex;
	height: 8vh;
}

#private-leaderboard-link2 {
	align-self: center;
}

#tqbc-navbar-logo {
    max-height: 8vh;
}

.navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.nav-item {
    text-align: center;
    align-self: center;
}

.navigation-bar {
    height: 8vh;
}

#report-an-issue-div {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    justify-content: right;
    margin-right: 1vw;
}

#report-an-issue-link {
    border: 1px solid grey;
    padding: 5px;
}